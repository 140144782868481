/* @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Montserrat:wght@300;400;500;600;700;800&family=Inter:wght@400;500;600;700&family=Old+Standard+TT:wght@400;700&family=Open+Sans:wght@400;500;600;700&family=Oswald:wght@400;500;600;700&family=Poppins:wght@300;400;500;600;700;800&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Montserrat:wght@300;400;500;600;700;800&family=Inter:wght@400;500;600;700&family=Old+Standard+TT:wght@400;700&family=Open+Sans:wght@400;500;600;700&family=Oswald:wght@400;500;600;700&family=Poppins:wght@300;400;500;600;700;800&family=Inter:wght@300;400;500;600;700;800&display=swap");

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; /* Remove default arrow */
}

select::-ms-expand {
  display: none; /* Hide the default arrow in Internet Explorer 10 and Internet Explorer 11 */
}

html {
  background-color: #f3f3f3;
  font-family: "Inter", sans-serif;
  overflow-x: hidden !important;
}
button {
  display: inline-block;
  border: none;
  text-decoration: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
textarea {
  white-space: pre-line;
}
.poppins,
.Poppins {
  font-family: "Poppins", sans-serif !important;
}

.bigHomeDiv {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: auto;
  min-height: 100vh;
  overflow-x: hidden;
  /* background: linear-gradient(
    115.99deg,
    #ffffff 7.11%,
    #e8e8e8 64.31%,
    #f3f3f3 109.75%
  ); */
  background-color: #ecedf5;
}

.projectContainer {
  margin-top: 40px;
  margin-left: 80px;
}
.appHeaderBio {
  margin-bottom: 20px;
}
.appHeaderBio > h1{
  margin: 0;
  font-size: 22px;
  padding-bottom: 10px;
}
.appHeaderBio > p{
  margin: 0;
  font-size: 13px;
  color: #444;
  padding-bottom: 10px;
}
.textInputContainer {
  margin: 15px 0px;
}
.checkboxDiv {
  display: flex;
  align-items: center;
}
.checkboxDiv > p {
  margin: 0;
  font-size: 13px;
  font-weight: 500;
}
.checkboxDiv > p > span {
  margin: 0;
  font-size: 11px;
  font-weight: 400;
}
.postDemoNoticeContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90vw;
  padding: 0px 5vw;
  background-color: rgb(164, 211, 247);
  height: 45px;
  transition: 1s;
}
.textSectionAlert {
  display: flex;
  align-items: center;
}
.textSectionAlert > svg {
  height: 16px;
  fill: #414141;
  margin-right: 10px;
}
.textSectionAlert > p {
  margin: 0;
  font-size: 13px;
  font-weight: 500;
  color: #444;
}
.textSectionAlert > p > span {
  padding: 0px 4px;
  text-decoration: underline;
}
.buttonInAlert {
  display: flex;
  align-items: center;
  border: 1px solid #333;
  padding: 4px 15px;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;
  background-color: rgb(230, 250, 254);
}
.buttonInAlert:hover {
  background-color: #fff;
}
.btnContainerPDFlow {
  display: flex;
  align-items: center;
}
.btnContainerPDFlow > span {
  color: #414141;
  font-size: 12px;
  font-weight: 500;
  margin: 0 10px;
}
.buttonContainerAlt {
  cursor: pointer;
  background-color: #3e3b66;
  color: #fff;
  font-size: 13px;
  width: auto;
  min-width: 90px;
  padding: 0 15px;
  height: 34px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inputContainer {
  width: auto;
  height: auto;
}
.inputContainer > textarea {
  width: 250px;
  height: 75px;
  border-radius: 8px;
}

.buttonContainer {
  cursor: pointer;
  background-color: #000;
  color: #fff;
  font-size: 13px;
  width: 120px;
  height: 30px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
}


.flex-container {
  display: flex;
  flex-direction: row;
}

.column {
  flex: 50%;
  padding: 10px;
}

th, td {
	border: 1px solid black;
	padding: 8px;
}

thead th {
  width: 25%;
}


.nav {
  width: 100vw;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #000;
}
.imageInNav {
  display: flex;
  align-items: center;
  margin-left: 40px;
  cursor: pointer;
}
.imageInNav > img {
  width: 45px;
}
.imageInNav > p {
  font-size: 13px;
  font-weight: 300;
  margin: 0;
  padding-left: 5px;
  color: #fff;
}
.logoutInNav {
  margin: 0;
  width: 5vw;
  font-size: 13px;
  padding-right: 40px;
  color: #fff;
  cursor: pointer;
}
.middleSectionNav {
  width: 70vw;
  display: flex;
  align-items: center;
}
.optionInNav {
  margin-right: 40px;
  font-size: 12px;
}
.optionInNav > p {
  margin: 0;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
}
.h2Home {
  font-size: 18px;
}
.backBtnHome {
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  width: 70px;
  text-decoration: underline;
}
.appList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.singleAppOption {
  width: 350px;
  padding: 20px 15px;
  height: auto;
  border: 1px solid #000;
  border-radius: 6px;
  margin-bottom: 20px;
  margin-right: 20px;
  cursor: pointer;
  transition: 0.2s;
  background-color: #ffffff90;
}
.singleAppOption:hover {
  background-color: #ffffff;
}
.headerOnOption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
}
.headerOnOption > p {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  padding-top: 0px;
  padding-bottom: 10px;
  height: 20px;
}
.descOnOption {
  font-size: 13px;
  margin-bottom: 4px;
}
.iconOnOption > svg {
  width: 30px;
}

.buttonContainerMini {
  cursor: pointer;
  background-color: #000;
  color: #fff;
  font-size: 13px;
  height: 30px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
}
